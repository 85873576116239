import { Action } from '@ngrx/store';

export const UNDO_ACTION = 'UNDO_ACTION';

export function undo(action: Action) {
  return {
    type: UNDO_ACTION,
    payload: action,
  };
}
